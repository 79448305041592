import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; //A hook from React Router that provides information about the current URL

const ScrollToTop = () => {
  const { pathname } = useLocation(); //useLocation() gives you an object containing various details about the current URL. We destructure pathname from it, which is the path of the current URL.

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); //The dependency array [pathname] means that the useEffect will run every time pathname changes, i.e., every time you navigate to a different route.

  return null; //The component doesn't need to render anything, so it returns null.
};

export default ScrollToTop;
