import http from "./http-common";

class RideDataService {
  getAll(page = 0) {
    return http.get(`rides/all`);
  }

  getDriverAll(id, page = 0) {
    return http.get(`rides/driver/${id}/all`);
  }

  get(id) {
    return http.get(`rides/${id}`);
  }

  getFromCode(data) {
    return http.get(
      `rides/check?email=${data.email}&ride_code=${data.ride_code}`
    );
  }

  createRide(data) {
    return http.post(`rides?api_key=${process.env.REACT_APP_API_KEY}`, data);
  }

  updateRideStatus(id, data) {
    return http.put(`rides/${id}/status`, data);
  }

  updateRideProposalStatus(id, data) {
    return http.put(`rides/${id}/proposal`, data);
  }

  updateRide(id, data) {
    return http.put(`rides/${id}`, data);
  }

  updateRideWithCode(code, data) {
    return http.put(
      `rides/code/${code}?api_key=${process.env.REACT_APP_API_KEY}`,
      data
    );
  }

  find(query, by = "name", page = 0) {
    return http.get(`?${by}=${query}&page=${page}`);
  }

  createReview(data) {
    return http.post("/review", data);
  }

  deleteReview(id, userId) {
    return http.delete(`/review?id=${id}`, { data: { user_id: userId } });
  }

  getCuisines(id) {
    return http.get(`/cuisines`);
  }
}

const rideDataServiceInstance = new RideDataService();
export default rideDataServiceInstance;
