// components/SecureRoute.js
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";
import { Navigate } from "react-router-dom";

const SecureRoute = ({ children }) => {
  const { authenticated, isLoading } = useContext(UserContext);

  //console.log("Authenticated status in SecureRoute:", authenticated);

  if (isLoading) {
    return <div>Loading...</div>; // or return null to show nothing
  }

  return authenticated ? children : <Navigate to="/login" replace />;
};

export default SecureRoute;
