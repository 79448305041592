import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:4005";

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api/v1/`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Use an interceptor to add the Authorization header before each request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Insert API Key for certain endpoints
axiosInstance.interceptors.request.use(
  (config) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    // List of routes that require an API key
    const routesThatRequireApiKey = [
      "users/register",
      "users/register/admin",
      "rides/check",
    ];

    // Check if the current request's URL is in the list that needs an API Key
    if (routesThatRequireApiKey.some((route) => config.url.includes(route))) {
      config.url +=
        (config.url.includes("?") ? "&" : "?") + `api_key=${apiKey}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Another interceptor for logging request data
axiosInstance.interceptors.request.use(
  (config) => {
    console.log("Making request to ", config.url);
    return config;
  },
  (error) => {
    console.log("Error in request: ", error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle specific data transformation
axiosInstance.interceptors.response.use(
  (response) => {
    // You might want to modify or log the response here
    console.log("Received response from ", response.config.url);
    return response;
  },
  (error) => {
    console.log("Error in response: ", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
