import http from "./http-common";

class UserDataService {
  getAllDrivers(page = 0) {
    return http.get(`users/drivers`);
  }

  getAllUsers(page = 0) {
    return http.get(`users/all`);
  }

  getUser(id) {
    return http.get(`users/${id}`);
  }

  updateUser(id, data) {
    return http.put(`users/${id}`, data);
  }

  createUser(data) {
    return http.post(`users/register`, data);
  }

  createAdmin(data) {
    return http.post(`users/register/admin`, data);
  }

  updateUserRoleByAdmin(id, data) {
    return http.put(`users/${id}/set/role`, data);
  }

  find(query, by = "name", page = 0) {
    return http.get(`?${by}=${query}&page=${page}`);
  }

  //oooooooooooooooooooooooooooold

  createReview(data) {
    return http.post("/review", data);
  }

  deleteReview(id, userId) {
    return http.delete(`/review?id=${id}`, { data: { user_id: userId } });
  }

  getCuisines(id) {
    return http.get(`/cuisines`);
  }
}

const userDataServiceInstance = new UserDataService();
export default userDataServiceInstance;
