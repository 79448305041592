// components/UserProvider.js

import React from "react";
import UserContext from "../contexts/UserContext";
import useVerifyToken from "../hooks/useVerifyToken";

const UserProvider = ({ children }) => {
  // Get the user, authenticated state, and logout function from the useVerifyToken hook
  const { user, authenticated, isLoading, logout, login } = useVerifyToken();

  return (
    <UserContext.Provider
      value={{ user, authenticated, isLoading, logout, login }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
