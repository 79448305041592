import { DateTime } from 'luxon';

// Get the current date and time
export const getCurrentDate = () => {
    return new Date();
  };
  
  // Get the current date in YYYY-MM-DD format
  export const getFormattedDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };
  
  // Get the current time in HH:MM:SS format
  export const getFormattedTime = () => {
    const now = new Date();
    const hh = String(now.getHours()).padStart(2, '0');
    const min = String(now.getMinutes()).padStart(2, '0');
    const sec = String(now.getSeconds()).padStart(2, '0');
    return `${hh}:${min}:${sec}`;
  };
  
  // Get the current day, month, and year separately
  export const getDayMonthYear = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return { day, month, year };
  };

  export const formatDate =(inputDate, time) => {
    const dateObj = new Date(inputDate);
    const dateInParis = DateTime.fromISO(inputDate).setZone('Europe/Paris');
    if (time)
        return dateInParis.toLocaleString(DateTime.DATETIME_SHORT);
    else
        return `${dateObj.getDate()}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()}`;
}
  