// contexts/UserContext.js

import { createContext } from "react";

const UserContext = createContext({
  user: null,
  authenticated: false,
  isLoading: true,
  login: () => {}, // default login function does nothing
  logout: () => {}, // default logout function does nothing
});

export default UserContext;
