import React  from 'react'

import { BiSolidPlaneTakeOff, BiSolidPlaneLand } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TypeAnimaationLanguages from './TypeAnimaationLanguages';
import config from '../config';


const Hero = () => {
    const { t  } = useTranslation();
    const primaryColor = config.env.primaryColor || "#00df9a";

    let to=t('Hero.between_1')
    let from=t('Hero.between_2')

    return (
        <div id="home" className='text-white'>
            <div className='max-w-[900px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <p className='font-bold p-2' style={{ color: primaryColor }}>{t('Hero.uptitle')}</p>
                <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 '> {t('Hero.main_1')} {' '}
                    <TypeAnimaationLanguages to={to} from={from} />
                 {t('Hero.main_2')}.</h1>
                <p className='md:text-2xl text-xl font-bold text-gray-500 pt-4'>{t('Hero.subtitle')}</p>
                <div className='mt-3'>
                    <Link to="/booking/destination">
                        <button className='w-[265px] text-center rounded-full font-medium sm:mr-3 mx-auto px-5 py-3 text-black hover:bg-indigo-400' style={{ backgroundColor: primaryColor }}>
                            {t('Hero.button_1')}<span className='inline-flex'><BiSolidPlaneTakeOff  size={20}/> </span>
                        </button>
                    </Link>
                    <Link to="/booking/departure">
                        <button className='w-[265px] rounded-full font-medium sm:ml-3 my-2 sm:my-0 mx-auto px-5 py-3 text-black hover:bg-indigo-400' style={{ backgroundColor: primaryColor }}>
                            {t('Hero.button_2')}<span className='inline-flex'><BiSolidPlaneLand size={20} /> </span>
                        </button>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}

export default Hero
