import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Datepicker from "react-tailwindcss-datepicker";

import RideDataService from "../../services/rides";

import {
  wcOptions,
  fraOptions,
  netOptions,
  luxOptions,
  gerOptions,
} from "../utils/locationOptions.js";
import { getStatusString } from "../utils/valuesHelper.js";
import countries from "../utils/countries";
import { formatDate } from "../utils/dateUtils";

const Edit = ({ id, driver }) => {
  const { t } = useTranslation();
  const [ride, setRide] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    status: 0,
    ride_code: "",
    createdAt: "",
    paid: 0,
    driver_price: 0,
    passengers: "",
    destination: "",
    departure: "",
    country: "",
    country_loc: "",
    bel_addr: "",
    price_validation: "",
    date: "",
    time: "",
    carseats: 0,
    boosterseats: 0,
    wheelchairs: "0",
    fligh_desti: "",
    fligh_origin: "",
    fligh_number: "",
    arrival_time: "",
    firstname: "",
    lastname: "",
    email: "",
    countrycode: "",
    phone: "",
    details: "",
    payment_mode: "",
  });

  const [datePicker, setDatePicker] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDatePickerValueChange = (newValue) => {
    setDatePicker(newValue);
    setFormData({
      ...formData,
      date: newValue.startDate,
    });
  };

  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorToast = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const updateRideProposalStatus = async (id, proposal) => {
    try {
      const data = { proposed: proposal };
      const response = await RideDataService.updateRideProposalStatus(id, data);

      if (response.status === 201) {
        // Update the local state to reflect the new status
        if (proposal === 2) {
          setFormData({ ...formData, proposed: proposal, status: 2 });
          setRide({ ...ride, proposed: proposal, status: 2 });
        } else if (proposal === 3) {
          setFormData({ ...formData, proposed: proposal, status: 1 });
          setRide({ ...ride, proposed: proposal, status: 1 });
        }

        //setRide(response.ride);
        successToast(
          response.data.message || t("Message.Proposal_updated_successfully")
        );
      } else {
        // Handle the case where the server responds, but the update is unsuccessful
        errorToast(
          response.data.message || t("Message.Error_updating_proposal")
        );
      }
    } catch (error) {
      // Handle any errors during the request
      errorToast(t("Message.Error_updating_proposal"));
      console.error("Error during status proposal:", error);
    }
  };

  const updateRideStatus = async (id, newStatus) => {
    try {
      const data = { status: newStatus };
      const response = await RideDataService.updateRideStatus(id, data);

      if (response.status === 201) {
        // Update the local state to reflect the new status
        setFormData({ ...formData, status: newStatus });
        //setRide(response.ride);
        successToast(response.data.message || "Status updated successfully");
      } else {
        // Handle the case where the server responds, but the update is unsuccessful
        errorToast(response.data.message || "Update failed");
      }
    } catch (error) {
      // Handle any errors during the request
      errorToast("Error updating status");
      console.error("Error during status update:", error);
    }
  };

  let bgColorClass;
  if (ride.proposed === 2) {
    bgColorClass = "text-black bg-green-400";
  } else if (ride.proposed === 3) {
    bgColorClass = "text-black bg-red-400";
  } else {
    bgColorClass = "text-white bg-white/5"; // Default background color
  }

  const retrieveRide = useCallback(() => {
    RideDataService.get(id)
      .then((response) => {
        let ride = response.data;
        //console.log(ride);
        setRide(ride);
        // Updating the formData state with the ride data
        setFormData((prevState) => ({
          ...prevState, // This is to keep any other values in the formData that aren't updated by the ride data
          ...ride, // This spreads the ride data onto the formData
        }));
        if (ride.date) {
          setDatePicker(() => ({
            startDate: new Date(ride.date),
            endDate: new Date(ride.date),
          }));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id]);

  useEffect(() => {
    retrieveRide();
  }, [retrieveRide]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlerrors = (errors, inputValues) => {
    // Validation logic
    if (!inputValues.passengers) {
      errors.passengers = "Passenger number is required";
    } else if (parseInt(inputValues.passengers) < 1) {
      errors.passengers = "Error with the passenger number";
    }

    if (!inputValues.destination) {
      if (!inputValues.departure) {
        errors.destination = "Destination is required";
      }
    } else if (
      inputValues.destination !== "bru" &&
      inputValues.destination !== "crl" &&
      inputValues.destination !== "NA"
    ) {
      console.log(inputValues.destination);
      errors.destination = "Destination is invalid";
    }

    if (inputValues.destination && !inputValues.time) {
      errors.time = "Depart time is required";
    }

    if (!inputValues.departure) {
      if (!inputValues.destination) {
        errors.departure = "Destination is required";
      }
    } else if (
      inputValues.departure !== "bru" &&
      inputValues.departure !== "crl" &&
      inputValues.departure !== "NA"
    ) {
      errors.departure = "Departure is invalid";
    }

    if (inputValues.departure !== "NA" && inputValues.departure !== "") {
      if (!inputValues.fligh_origin) {
        errors.fligh_origin = "Depart Origin is required";
      }
      if (!inputValues.fligh_number) {
        errors.fligh_number = "Flight number is required";
      }
      if (!inputValues.arrival_time) {
        errors.arrival_time = "Arrival time is required";
      }
    }

    if (!inputValues.country) {
      errors.country = "Country is required";
    }

    /*if (!inputValues.driver_price) {
      errors.driver_price = "The driver price has been not defined";
    } else if (parseInt(inputValues.driver_price) < 42) {
      errors.driver_price = "Error with the pricing. Less than 42";
    }*/

    if (!inputValues.date) {
      errors.date = "Date is required";
    }

    if (!inputValues.firstname) {
      errors.firstname = "Firstname is required";
    }

    if (!inputValues.lastname) {
      errors.lastname = "Lastname is required";
    }

    if (!inputValues.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = "Email address is invalid";
    }

    if (!inputValues.countrycode) {
      errors.countrycode = "Country code is required";
    }

    if (!inputValues.phone) {
      errors.phone = "Phone code is required";
    }

    if (!inputValues.payment_mode) {
      errors.payment_mode = "Payment mode is required";
    }
    return errors;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    //console.log(formData)

    let errors = {};
    errors = handlerrors(errors, formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (formData.bel_addr === "") {
        formData.bel_addr = formData.country_loc;
      }

      //axiooooooooooooooooooooooooooooooos
      //console.log("Before sending updated formData");
      try {
        const { data } = await RideDataService.updateRide(id, formData);
        const { success, message, ride } = data;
        if (success) {
          successToast("Ride updated successfully");
          console.log("success:", message);
          setRide(ride);
        } else {
          errorToast("The server could not manage the request.");
          console.log("Not success:", message);
        }
      } catch (error) {
        errorToast("We can not contact the server.");
        console.log(error);
      }
    } else {
      errorToast("There are errors in the form.");
      //console.log(errors)
    }
  };

  return (
    <div className="text-white">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h1 className="text-xl font-semibold leading-8 text-white pb-5">
              {t("EditPage.link_driver")}
            </h1>

            {ride.proposed !== 0 && (
              <>
                <h2 className="text-base leading-6 text-white">
                  {t("EditPage.accept_proposal")} ?{" "}
                  {ride.proposed === 2 && (
                    <span className="text-green-400">
                      {t("EditPage.accept")}
                    </span>
                  )}
                  {ride.proposed === 3 && (
                    <span className="text-red-400">{t("EditPage.refuse")}</span>
                  )}
                </h2>
                <div className="mx-auto grid grid-cols-2 md:grid-cols-2 pb-7">
                  <button
                    type="button"
                    disabled={
                      //status: 0= created, 1=validated, 2=assigned
                      (ride.proposed === 2 || ride.status !== 2) && true
                    }
                    onClick={() => updateRideProposalStatus(id, 2)}
                    className="disabled:text-slate-500 md:col-span-1 rounded-md bg-green-400 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    {t("EditPage.accept")}
                  </button>

                  <button
                    type="button"
                    disabled={
                      (ride.proposed === 3 || ride.status !== 2) && true
                    }
                    onClick={() => updateRideProposalStatus(id, 3)}
                    className="disabled:text-slate-500 md:col-span-1 rounded-md bg-red-400 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    {t("EditPage.refuse")}
                  </button>
                </div>
              </>
            )}
            {ride.proposed === 2 && (
              <>
                {" "}
                <h2 className="text-base font-semibold leading-7 text-white">
                  {t("EditPage.update_ride_status")}
                </h2>
                <div className="mx-auto grid grid-cols-3 md:grid-cols-3">
                  <button
                    type="button"
                    disabled={
                      //2=assigned, 3=canceled, 4=deleted, 5=done,  6=start , 7=ride issues
                      formData.status !== 2 && true
                    }
                    onClick={() => updateRideStatus(id, 6)}
                    className="disabled:text-slate-500 md:col-span-1 rounded-md bg-blue-400 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    {t("Table.start")}
                  </button>
                  <button
                    type="button"
                    disabled={
                      ((formData.status !== 6 && formData.status !== 7) ||
                        formData.status === 5) &&
                      true
                    }
                    onClick={() => updateRideStatus(id, 5)}
                    className="disabled:text-slate-500 md:col-span-1 rounded-md bg-green-900 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    {t("Table.done")}
                  </button>
                  <button
                    type="button"
                    disabled={
                      ((formData.status !== 6 && formData.status !== 5) ||
                        formData.status === 7) &&
                      true
                    }
                    onClick={() => updateRideStatus(id, 7)}
                    className="disabled:text-slate-500 md:col-span-1 rounded-md bg-orange-300 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    {t("Table.issue")}
                  </button>
                </div>
              </>
            )}

            <div className="border-t border-white mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="driver"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("Table.driver")}
                </label>
                <div className="mt-2">
                  <input
                    disabled={true}
                    onChange={handleChange}
                    value={driver.firstname + " " + driver.lastname}
                    id="driver"
                    name="driver"
                    type="text"
                    className={`block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:${bgColorClass} disabled:text-slate-700`}
                  />
                </div>
              </div>
              <div className="sm:col-span-1 sm:col-start-1">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("Table.status")}
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    value={t(getStatusString(formData.status))}
                    id="status"
                    name="status"
                    type="text"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="ride_code"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("Table.code")}
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    value={formData.ride_code}
                    type="text"
                    name="ride_code"
                    id="ride_code"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-4 dark border-t border-white/10 pt-6">
                <label
                  htmlFor="date"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.date")}
                </label>
                <div className="mt-2">
                  <Datepicker
                    disabled={true}
                    name="date"
                    id="date"
                    value={datePicker}
                    onChange={handleDatePickerValueChange}
                    primaryColor={"green"}
                    className="dark:bg-gray-800 dark:text-white"
                    asSingle={true}
                    useRange={false}
                    minDate={new Date()}
                    maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="time"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.time")}
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    onChange={handleChange}
                    value={formData.time}
                    id="time"
                    name="time"
                    type="time"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:bg-white/20 disabled:text-slate-500"
                  />
                  {formErrors.departure && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.departure}
                    </p>
                  )}
                </div>
              </div>
              <div className="sm:col-span-4 border-b border-white/10 pb-12">
                <label
                  htmlFor="passengers"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("FormType.passengers")}
                </label>
                <div className="mt-2">
                  <select
                    disabled
                    id="passengers"
                    name="passengers"
                    value={formData.passengers}
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                  >
                    {Array.from({ length: 56 }, (_, i) => i + 1).map(
                      (number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      )
                    )}
                  </select>
                  {formErrors.passengers && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="passengers-error"
                    >
                      {formErrors.passengers}
                    </p>
                  )}
                </div>
              </div>
              {formData.departure !== "NA" && (
                <>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="departure"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      {t("FormType.depart")}
                    </label>
                    <div className="mt-2">
                      <select
                        disabled
                        id="departure"
                        name="departure"
                        value={formData.departure}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                      >
                        <option value="" disabled>
                          {t("FormType.type_select")}
                        </option>
                        <option value="bru">
                          {t("Hero.between_2")} Brussels Zaventem Airport
                          (BRU/EBBR)
                        </option>
                        <option value="crl">
                          {t("Hero.between_2")} Brussels South Charleroi Airport
                          (CRL/EBCI)
                        </option>
                      </select>
                      {formErrors.departure && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {formErrors.departure}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      htmlFor="fligh_desti"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      {t("Flight.destination")}
                    </label>
                    <div className="mt-2">
                      <input
                        disabled
                        onChange={handleChange}
                        value={formData.fligh_desti}
                        type="text"
                        name="fligh_desti"
                        id="fligh_desti"
                        className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                      />
                      {formErrors.fligh_desti && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {formErrors.fligh_desti}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="fligh_origin"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      {t("Flight.origin")}
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={handleChange}
                        disabled
                        value={formData.fligh_origin}
                        type="text"
                        name="fligh_origin"
                        id="fligh_origin"
                        className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                      />
                      {formErrors.fligh_origin && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {formErrors.fligh_origin}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      htmlFor="fligh_number"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      {t("Flight.number")}
                    </label>
                    <div className="mt-2">
                      <input
                        disabled
                        onChange={handleChange}
                        value={formData.fligh_number}
                        type="text"
                        name="fligh_number"
                        id="fligh_number"
                        className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                      />
                      {formErrors.fligh_number && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {formErrors.fligh_number}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="arrival_time"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      {t("Flight.a_time")}
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={handleChange}
                        disabled
                        value={formData.fligh_number}
                        id="arrival_time"
                        name="arrival_time"
                        type="text"
                        className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                      />
                      {formErrors.fligh_number && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {formErrors.fligh_number}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
              {formData.destination !== "NA" && (
                <div className="sm:col-span-4">
                  <label
                    htmlFor="destination"
                    className="block text-sm font-medium leading-6 text-white "
                  >
                    {t("FormType.destination")}
                  </label>
                  <div className="mt-2">
                    <select
                      disabled
                      id="destination"
                      name="destination"
                      value={formData.destination}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                    >
                      <option value="" disabled>
                        {t("FormType.type_select_2")}
                      </option>
                      <option value="bru">
                        {t("Hero.between_1")} Brussels Zaventem Airport
                        (BRU/EBBR)
                      </option>
                      <option value="crl">
                        {t("Hero.between_1")} Brussels South Charleroi Airport
                        (CRL/EBCI)
                      </option>
                    </select>
                    {formErrors.destination && (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="destination-error"
                      >
                        {formErrors.destination}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              {t("Table.address")}
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("FormType.country")}
                </label>
                <div className="mt-2">
                  <select
                    disabled
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                  >
                    <option value="" disabled>
                      {t("FormType.country_select")}
                    </option>
                    <option value="bel">{t("FormType.bel")}</option>
                    <option value="fra">{t("FormType.fra")}</option>
                    <option value="lux">{t("FormType.lux")}</option>
                    <option value="net">{t("FormType.net")}</option>
                    <option value="ger">{t("FormType.ger")}</option>
                  </select>
                  {formErrors.country && (
                    <p className="mt-2 text-sm text-red-600" id="country-error">
                      {formErrors.country}
                    </p>
                  )}
                </div>
              </div>
              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="country_loc"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("FormType.local")}
                </label>
                <div className="mt-2">
                  {formData.country === "bel" && (
                    <select
                      disabled
                      id="country_loc"
                      name="country_loc"
                      value={formData.country_loc}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                    >
                      <option value="" disabled>
                        {t("FormType.loc_select_2")}
                      </option>
                      <option value="private">{t("FormType.loc_bel_1")}</option>
                      {formData.destination === "crl" && (
                        <option value="bru">
                          Brussels Zaventem Airport (BRU / EBBR)
                        </option>
                      )}
                      {formData.destination === "bru" && (
                        <option value="crl">
                          Brussels South Charleroi Airport (CRL/EBCI)
                        </option>
                      )}
                      <option value="midi">
                        Bruxelles-Midi railway station
                      </option>
                      <option value="central">
                        Bruxelles-Central railway station
                      </option>
                      <option value="nord">
                        Bruxelles-Nord rail way station
                      </option>
                    </select>
                  )}
                  {formData.country === "lux" && (
                    <select
                      disabled
                      id="country_loc"
                      name="country_loc"
                      value={formData.country_loc}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                    >
                      {luxOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {t(option.label)}
                        </option>
                      ))}
                    </select>
                  )}
                  {formData.country === "fra" && (
                    <select
                      disabled
                      id="country_loc"
                      name="country_loc"
                      value={formData.country_loc}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                    >
                      {fraOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {t(option.label)}
                        </option>
                      ))}
                    </select>
                  )}
                  {formData.country === "net" && (
                    <select
                      disabled
                      id="country_loc"
                      name="country_loc"
                      value={formData.country_loc}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                    >
                      {netOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {t(option.label)}
                        </option>
                      ))}
                    </select>
                  )}
                  {formData.country === "ger" && (
                    <select
                      disabled
                      id="country_loc"
                      name="country_loc"
                      value={formData.country_loc}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                    >
                      {gerOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {t(option.label)}
                        </option>
                      ))}
                    </select>
                  )}
                  {formErrors.country_loc && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.country_loc}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="bel_addr"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("FormType.loc_write")}
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    onChange={handleChange}
                    value={formData.bel_addr}
                    type="text"
                    name="bel_addr"
                    id="regibel_addron"
                    autoComplete="address-level1"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                  />
                  {formErrors.bel_addr && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.bel_addr}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              {t("Table.details")}
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="carseats"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.car_seats")}
                </label>
                <div className="mt-2">
                  <select
                    disabled
                    value={formData.carseats}
                    onChange={handleChange}
                    id="carseats"
                    name="carseats"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                  {formErrors.carseats && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.dacarseatste}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="boosterseats"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.boost_seats")}
                </label>
                <div className="mt-2">
                  <select
                    disabled
                    id="boosterseats"
                    value={formData.boosterseats}
                    onChange={handleChange}
                    name="boosterseats"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                  {formErrors.boosterseats && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.boosterseats}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="wheelchairs"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.wc")}
                </label>
                <div className="mt-2">
                  <select
                    disabled
                    id="wheelchairs"
                    value={formData.wheelchairs}
                    onChange={handleChange}
                    name="wheelchairs"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                  >
                    {wcOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.nbr} {t(option.label)}{" "}
                        {option.nbr_2 &&
                          `+ ${option.nbr_2} ${t(option.label_2)}`}
                      </option>
                    ))}
                  </select>
                  {formErrors.wheelchairs && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.wheelchairs}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              {t("User.info_personal")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400"></p>
            {t("EditPage.message")}
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.firstname")}
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    onChange={handleChange}
                    value={formData.firstname}
                    type="text"
                    name="firstname"
                    id="firstname"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                  />
                  {formErrors.firstname && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.firstname}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.lastname")}
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    onChange={handleChange}
                    value={formData.lastname}
                    type="text"
                    name="lastname"
                    id="lastname"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                  />
                  {formErrors.lastname && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.lastname}
                    </p>
                  )}
                </div>
              </div>
              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="countrycode"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.country_code")}
                </label>
                <div className="mt-2">
                  <select
                    disabled
                    id="countrycode"
                    value={formData.countrycode}
                    name="countrycode"
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                  >
                    <option value="" disabled>
                      {t("MainForm.country_code_select")}
                    </option>
                    {countries.map(([country, _, __, code], index) => (
                      <option key={index} value={code}>
                        {`${country} (+${code})`}
                      </option>
                    ))}
                  </select>
                  {formErrors.country_code_select && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.country_code_select}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.phone")}
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    onChange={handleChange}
                    value={formData.phone}
                    type="text"
                    name="phone"
                    id="phone"
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                  />
                  {formErrors.phone && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.phone}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="details"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.more")}
                </label>
                <div className="mt-2">
                  <textarea
                    disabled
                    onChange={handleChange}
                    value={formData.details}
                    id="details"
                    name="details"
                    rows={3}
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 disabled:text-slate-500"
                  />
                  {formErrors.more && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.more}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              {t("Table.billing")}
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="driver_price"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.pricing")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    disabled
                    value={formData.driver_price}
                    type="text"
                    name="driver_price"
                    id="driver_price"
                    className="disabled:text-slate-500 block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.driver_price && (
                    <p className="mt-2 text-sm text-red-600" id="time-error">
                      {formErrors.driver_price}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="payment_mode"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.payment")}
                </label>
                <div className="mt-2">
                  <select
                    id="payment_mode"
                    name="payment_mode"
                    disabled
                    value={formData.payment_mode}
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black disabled:text-slate-500"
                  >
                    <option value="cash">{t("MainForm.mode_1")}</option>
                    <option value="card">{t("MainForm.mode_2")}</option>
                  </select>
                  {formErrors.payment_mode && (
                    <p className="mt-2 text-sm text-red-600" id="time-error">
                      {formErrors.payment_mode}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-white/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-400">
              {t("Table.updated_at")}: {formatDate(ride.updated_at, true)}
            </p>
          </div>
        </div>
      </form>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default Edit;
