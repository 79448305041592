// valuesHelper.js

const getStatusString = (statusCode) => {
  switch (statusCode) {
    case 0:
      return "Table.to_validate";
    case 1:
      return "Table.validated";
    case 2:
      return "Table.assigned";
    case 3:
      return "Table.canceled";
    case 4:
      return "Table.deleted";
    case 5:
      return "Table.done";
    case 6:
      return "Table.start";
    case 7:
      return "Table.issue";
    default:
      return "Unknown";
  }
};

const getPaidString = (statusCode) => {
  switch (statusCode) {
    case 0:
      return "Table.to_pay";
    case 1:
      return "Table.paid";
    case 2:
      return "Table.deposit";
    default:
      return "Unknown";
  }
};

const getValidationString = (answer) => {
  switch (answer) {
    case "yes":
      return "Table.yes";
    case "no":
      return "Table.no";
    default:
      return "Unknown";
  }
};

const getPaymentString = (mode) => {
  switch (mode) {
    case "cash":
      return "Table.cash";
    case "card":
      return "Table.card";
    default:
      return "Unknown";
  }
};

const getWcString = (code) => {
  switch (code) {
    case "0":
      return "0";
    case "1c":
      return "Edit.wc_co";
    case "2c":
      return "Edit.two_wc_co";
    case "1nc":
      return "Edit.wc_no_co";
    case "2nc":
      return "Edit.two_wc_no_co";
    case "1c1nc":
      return "Edit.wc_co_wc_no_co";
    default:
      return "Unknown";
  }
};

const getCodeString = (code) => {
  switch (code) {
    case "NA":
      return "-";
    case "bel":
      return "FormType.bel";
    case "fra":
      return "FormType.fra";
    case "lux":
      return "FormType.lux";
    case "net":
      return "FormType.net";
    case "ger":
      return "FormType.ger";
    case "bru":
      return "Brussels Zaventem Airport (BRU/EBBR)";
    case "clr":
      return "Brussels South Charleroi Airport (CRL/EBCI)";
    case "midi":
      return "Bruxelles-Midi railway station";
    case "central":
      return "Bruxelles-Central railway station";
    case "nord":
      return "Bruxelles-Nord rail way station";

    default:
      return "Unknown";
  }
};

export {
  getWcString,
  getStatusString,
  getPaidString,
  getPaymentString,
  getValidationString,
  getCodeString,
};
