import React from "react";
import { TypeAnimation } from "react-type-animation";
import { useTranslation } from "react-i18next";

const TypeAnimaationLanguages = ({ to, from }) => {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.language === "en" ? (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            to,
            2000, // wait 1s before replacing "BTB" with "BTC"
            from,
            2000,
          ]}
          wrapper="span"
          speed={10}
          className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6"
          style={{ display: "inline-block" }}
          repeat={Infinity}
        />
      ) : null}

      {i18n.language === "es" ? (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            to,
            2000, // wait 1s before replacing "BTB" with "BTC"
            from,
            2000,
          ]}
          wrapper="span"
          speed={10}
          className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6"
          style={{ display: "inline-block" }}
          repeat={Infinity}
        />
      ) : null}
      {i18n.language === "de" ? (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            to,
            2000, // wait 1s before replacing "BTB" with "BTC"
            from,
            2000,
          ]}
          wrapper="span"
          speed={10}
          className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6"
          style={{ display: "inline-block" }}
          repeat={Infinity}
        />
      ) : null}
      {i18n.language === "po" ? (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            to,
            2000, // wait 1s before replacing "BTB" with "BTC"
            from,
            2000,
          ]}
          wrapper="span"
          speed={10}
          className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6"
          style={{ display: "inline-block" }}
          repeat={Infinity}
        />
      ) : null}
      {i18n.language === "it" ? (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            to,
            2000, // wait 1s before replacing "BTB" with "BTC"
            from,
            2000,
          ]}
          wrapper="span"
          speed={10}
          className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6"
          style={{ display: "inline-block" }}
          repeat={Infinity}
        />
      ) : null}
      {i18n.language === "fr" ? (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            to,
            2000, // wait 1s before replacing "BTB" with "BTC"
            from,
            2000,
          ]}
          wrapper="span"
          speed={10}
          className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6"
          style={{ display: "inline-block" }}
          repeat={Infinity}
        />
      ) : null}
      {i18n.language === "ne" ? (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            to,
            2000, // wait 1s before replacing "BTB" with "BTC"
            from,
            2000,
          ]}
          wrapper="span"
          speed={10}
          className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6"
          style={{ display: "inline-block" }}
          repeat={Infinity}
        />
      ) : null}
    </>
  );
};

export default TypeAnimaationLanguages;
