export const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "[...]";
  }
  return text;
};

export const roleLabel = (role) => {
  // -2 = deleted -1 = frozen 0= just created, 1=admin, 2 = driver
  const roles = {
    "-2": "Deleted",
    "-1": "Frozen",
    0: "Just created",
    1: "Admin",
    2: "Driver",
  };

  // Check if the role exists in the roles dictionary
  if (role in roles) {
    return roles[role];
  } else {
    // Handle undefined roles by returning a default message or throw an error
    return "Unknown Role"; // Alternatively, you could throw an error
    // throw new Error("Role not recognized.");
  }
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};
