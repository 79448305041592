import React from "react";
import Condition from "../components/Condition";
import config from "../config";

const Terms = () => {
  const transferItems = [
    {
      number: "1.1",
      sentence:
        "A transfer is the movement of one or more passengers from one location to another.",
    },
    {
      number: "1.2",
      sentence:
        "The cost of transfer depends on the type of vehicle and the distance between two locations. The price does not depend on address within the city limits.",
    },
    {
      number: "1.3",
      sentence:
        "The transfer is carried out with a vehicle which corresponds to the number of passengers and pieces of luggage.",
    },
    {
      number: "1.4",
      sentence:
        "The location of departure and destination must have a particular address (e.g., airport, sea port, railway station, bus station or any other specific address within the city limits).",
    },
    {
      number: "1.5",
      sentence:
        "The driver waits for the client at the pickup location holding a board with client’s last name and on it.",
    },
  ];

  const bookingItems = [
    {
      number: "2.1",
      sentence:
        "Type or choose a city or an airport as your Pick-up and Drop-off locations.",
    },
    {
      number: "2.2",
      sentence:
        "Select the right number of passengers. Please be aware that all children (regardless of their age) are counted as passengers and must have an individual seat.",
    },
    {
      number: "2.3",
      sentence:
        "Enter your travel details: address, flight number (or your bus, train, ship information) as well as your contact information. Make sure the mobile phone number you entered is correct and the phone will be with you at the time of arrival.",
    },
    {
      number: "2.4",
      sentence:
        "If necessary, please set the number of additional stop needed and complete for each one the complete address of it. Please note that, for each stop, an extra charge of, at least, 10.00 € will be added to the basic price provided on our website.",
    },
    {
      number: "2.5",
      sentence: `In order to confirm the booking, ${config.appName} sends you a confirmation email. If you do not receive this email within the next 2 hours following the introduction of your booking, please check your spam box and then contact our service.`,
    },
    {
      number: "2.7",
      sentence:
        "The client is responsible for the accuracy of all of the travel details. You must check and ensure that all information you provide is correct and complete. In case of transfer failure due to incorrect data (e.g., contact information, pickup time and place), our service will not assume any responsibility and can request to be paid for the booking confirmed. Make sure the time you enter is the local time in the country of transfer.",
    },
  ];

  const bookingSubtitle =
    "To make a booking, you must fill in all the required fields on the online booking form. You must enter your information using Latin characters.";

  const changesItems = [
    {
      number: "3.1",
      sentence:
        "All orders must be cancelled no later than 24 hours before the time of the beginning of transfer. To cancel an order the client should send an e-mail to our service with order details (order number and the reason for cancellation) or follow the cancellation link in the order confirmation e-mail.",
    },
    {
      number: "3.2",
      sentence:
        "Any changes (such as transfer date and time, destination, etc.) must be made no later than 24 hours before the transfer starts.",
    },
    {
      number: "3.3",
      sentence:
        "The client must inform the customer support about the changes 24 hours prior to the transfer via e-mail by sending a message to our service. Changes come into force right after they have been confirmed by a customer support representative.",
    },
    {
      number: "3.5",
      sentence:
        "In case of emergency changes (less than 24 hours before the transfer starts) the client must inform our service. Our team will estimate if there is some possibility to accept the modification or not, free or with a potential extra charge.",
    },
  ];

  const meetingItems = [
    {
      number: "4.1",
      sentence:
        "The driver must meet the client at the pick-up location at the time specified at the end of the booking process (on your computer screen). The driver will be holding a board with client’s name (as noted in the booking) on it.",
    },
    {
      number: "4.2",
      sentence:
        "Your driver will be waiting for you following the next rules:\n\n- Hotel, private address or public place: 15 min.\n\n- Airport: 45 min. after the official landing time of the flight.\n\nIf within this time the client and his driver fail to meet, the order shall be considered as a no-show.",
    },
    {
      number: "4.3",
      sentence:
        "The driver may want to contact the client by mobile phone prior to departure to arrange the meeting.",
    },
    {
      number: "4.4",
      sentence:
        "If for some reason the driver cannot pick up the client in time, he must inform the client in advance.",
    },
    {
      number: "4.5",
      sentence:
        "The client must be waiting for the driver at the meeting point at the time specified in the booking.",
    },
    {
      number: "4.6",
      sentence:
        "For any problem, please contact our emergency line (see this number on our website)",
    },
    {
      number: "4.7",
      sentence:
        "If the driver fails to show up at the pick-up location at the time specified in the booking within 15-20 minutes, the transfer shall be considered cancelled. In order to receive a potential compensation, the client must prove that he was, in fact, waiting for his driver at the time and place specified in the booking. A picture of the time board showing the current time and date will serve as acceptable proof. The client must send an e-mail with all the details to our service",
    },
  ];

  const meetingSubtitle = "For more information, contact our customer service.";

  const luggageItems = [
    {
      number: "5.1",
      sentence:
        "The number of allowed luggage pieces corresponds to the flight company standards. If there is excess luggage it is the client’s duty to inform customer support about it.",
    },
    {
      number: "5.2",
      sentence:
        "If you have extra-size items with you, it is your duty to inform customer support in advance by e-mail.",
    },
    {
      number: "5.3",
      sentence: `Animals are welcome. They have to travel in a cage, appropriate, to be clean, friendly and reported by email to ${config.appName}, at least, 48 hours before the pick-up date & time set in the booking. If the client has not informed ${config.appName} that he would like to travel with animals, the driver can refuse to operate the mission and this one will be considered as cancelled and has to be paid.`,
    },
  ];

  const priceItems = [
    {
      number: "6.2",
      sentence:
        "The payment has to be done like this: To the driver (only in cash) or In advance by credit card (to prepay a booking, please contact our customer support)",
    },
    {
      number: "6.3",
      sentence: "All payments must be made either in Euros (€)",
    },
    {
      number: "6.4",
      sentence: `${config.appName} is allowed to request to prepay a booking, depending on the parameters of the booking and/or the client’s stats. If the client is not agreeing to prepay the booking as required by ${config.appName}, the booking will be cancelled without any advice.`,
    },
  ];

  const restrictionsItems = [
    {
      number: "7.1",
      sentence:
        "Smoking and drinking is prohibited in the vehicle. In case of violation the driver has the right to stop the car and ask you to leave.",
    },
    {
      number: "7.2",
      sentence:
        "The number of passengers cannot go beyond the seating capacity of the vehicle. Transportation of extra passengers is not allowed. In this case, the driver has the right to refuse service. If so, no deposit will be refunded.",
    },
  ];

  const delayItems = [
    {
      number: "8.1",
      sentence:
        "Your mobile phone (linked to the phone number in your voucher) should be switched on arrival, as your driver may want to contact you.",
    },
    {
      number: "8.2",
      sentence: `In case of flight delay the client must contact ${config.appName} and report the delay. Most of the drivers are aware of changes as monitoring delays. However, a flight delay can result in your transfer being cancelled. In this case, ${config.appName} will call to inform you. Please note that we can provide you the transfer for a reasonable delay of the flight, depending on the situation and the daily planning.`,
    },
  ];

  const compCancelItems = [
    {
      number: "9.1",
      sentence:
        "An order can be cancelled by the manager for any of the following reasons:",
    },
    {
      number: "9.1.1",
      sentence:
        "The number of passengers exceeds the vehicle seating capacity;",
    },
    {
      number: "9.1.2",
      sentence:
        "The client failed to provide correct travel details (flight, route, dates);",
    },
    {
      number: "9.1.3",
      sentence: "The client failed to provide correct contact information;",
    },
    {
      number: "9.1.4",
      sentence: `The client refused to operate the processes required by ${config.appName}.`,
    },
    {
      number: "9.2",
      sentence:
        "The customer will be informed of cancellation in advance by e-mail.",
    },
  ];

  return (
    <div className="w-full py-16 px-4 text-white">
      <div className="max-w-[1240px] mx-auto">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          Terms and Conditions
        </h1>
        <p className="lg:text-2xl md:text-xl text-base">
          "{config.appName}" acts as an agent for transfer providers (i.e.,
          third party transportation companies and taxi services). "
          {config.appName}" provides searching and booking of transfers on its
          website. "{config.appName}" works with local operator. The contract
          for the provision of transportation services is concluded between You
          and the Provider.
        </p>
      </div>
      <div className="max-w-[1240px] mx-auto mt-5">
        <Condition title_number="1" title="Transfer" items={transferItems} />
        <Condition
          title_number="2"
          title="Booking"
          subtitle={bookingSubtitle}
          items={bookingItems}
        />
        <Condition
          title_number="3"
          title="Changes and Cancellation Policies"
          items={changesItems}
        />
        <Condition
          title_number="4"
          title="Meeting the Driver"
          subtitle={meetingSubtitle}
          items={meetingItems}
        />
        <Condition title_number="5" title="Luggage" items={luggageItems} />
        <Condition
          title_number="6"
          title="Prices and Payment options"
          items={priceItems}
        />
        <Condition
          title_number="7"
          title="Restrictions"
          items={restrictionsItems}
        />
        <Condition title_number="8" title="Flight delay" items={delayItems} />
        <Condition
          title_number="9"
          title="Cancellations made by the manager"
          items={compCancelItems}
        />
      </div>
    </div>
  );
};

export default Terms;
