import React, { useEffect } from "react";
//import BookRideForm from "../components/Form/BookRideForm";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import config from "../config";

const Booking = () => {
  const { mode } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const effectiveMode = mode ?? "departure"; // Replace 'defaultMode' with whatever default value you want
  const passengers = searchParams.get("passengers") || 1; // Default to 1 if not provided
  const airport = searchParams.get("airport") || ""; // Default to an empty string if not provided

  // Redirect if mode is not 'departure' or 'destination'
  useEffect(() => {
    if (mode !== "departure" && mode !== "destination") {
      navigate("/"); // Redirect to the home page
    }
  }, [mode, navigate]);

  return (
    <div className="w-full py-16 px-4">
      <config.pricing_component
        mode={effectiveMode}
        passengers={passengers}
        airport={airport}
      />
    </div>
  );
};

export default Booking;
