// locationOptions.js

const fraOptions = [
    { value: '', label: 'FormType.loc_select_2', disabled: true },
    { value: 'arras', label: 'ARRAS' },
    { value: 'cambrai', label: 'CAMBRAI' },
    { value: 'charleville mezieres', label: 'CHARLEVILLE MEZIERES' },
    { value: 'dunkerque', label: 'DUNKERQUE' },
    { value: 'hirson', label: 'HIRSON' },
    { value: 'laon', label: 'LAON' },
    { value: 'lille', label: 'LILLE' },
    { value: 'metz', label: 'METZ' },
    { value: 'nancy', label: 'NANCY' },
    { value: 'paris', label: 'PARIS' },
    { value: 'reims', label: 'REIMS' },
    { value: 'rethel', label: 'RETHEL' },
    { value: 'rocroi', label: 'ROCROI' },
    { value: 'roubaix', label: 'ROUBAIX' },
    { value: 'saint amand les eaux', label: 'SAINT AMAND LES EAUX' },
    { value: 'saint quentin', label: 'SAINT QUENTIN' },
    { value: 'thionville', label: 'THIONVILLE' },
    { value: 'tourcoing', label: 'TOURCOING' },
    { value: 'trith saint leger', label: 'TRITH SAINT LEGER' },
    { value: 'valenciennes', label: 'VALENCIENNES' }
];

const netOptions = [
    { value: '', label: 'FormType.loc_select_2', disabled: true },
    { value: 'amsterdam', label: 'AMSTERDAM' },
    { value: 'apeldoorn', label: 'APELDOORN' },
    { value: 'arnhem', label: 'ARNHEM' },
    { value: 'assen', label: 'ASSEN' },
    { value: 'breda', label: 'BREDA' },
    { value: 'den haag', label: 'DEN HAAG' },
    { value: 'dordrecht', label: 'DORDRECHT' },
    { value: 'eindhoven', label: 'EINDHOVEN' },
    { value: 'etten-leur', label: 'ETTEN-LEUR' },
    { value: 'groningen', label: 'GRONINGEN' },
    { value: 'heerlen', label: 'HEERLEN' },
    { value: 'helmond', label: 'HELMOND' },
    { value: 'maastricht', label: 'MAASTRICHT' },
    { value: 'nijmegen', label: 'NIJMEGEN' },
    { value: 'oss', label: 'OSS' },
    { value: 'roosendaal', label: 'ROOSENDAAL' },
    { value: 'rotterdam', label: 'ROTTERDAM' },
    { value: 's-hertogenbosch', label: 'S-HERTOGENBOSCH' },
    { value: 'sittard', label: 'SITTARD' },
    { value: 'terneuzen', label: 'TERNEUZEN' },
    { value: 'tilburg', label: 'TILBURG' },
    { value: 'utrecht', label: 'UTRECHT' },
    { value: 'venlo', label: 'VENLO' },
    { value: 'vlissingen', label: 'VLISSINGEN' },
    { value: 'zwolle', label: 'ZWOLLE' }
];

const luxOptions = [
    { value: '', label: 'FormType.loc_select_2', disabled: true },
    { value: 'capellen', label: 'CAPELLEN' },
    { value: 'diekirch', label: 'DIEKIRCH' },
    { value: 'differdange', label: 'DIFFERDANGE' },
    { value: 'dippach', label: 'DIPPACH' },
    { value: 'dudelange', label: 'DUDELANGE' },
    { value: 'ettelbruck', label: 'ETTELBRUCK' },
    { value: 'luxembourg-ville', label: 'LUXEMBOURG-VILLE' },
    { value: 'mersch', label: 'MERSCH' },
    { value: 'wiltz', label: 'WILTZ' }
];

const gerOptions = [
    { value: '', label: 'FormType.loc_select_2', disabled: true },
    { value: 'aachen', label: 'AACHEN' },
    { value: 'bonn', label: 'BONN' },
    { value: 'dortmund', label: 'DORTMUND' },
    { value: 'duisburg', label: 'DUISBURG' },
    { value: 'duren', label: 'DUREN' },
    { value: 'dusseldorf', label: 'DUSSELDORF' },
    { value: 'essen', label: 'ESSEN' },
    { value: 'frankfurt', label: 'FRANKFURT' },
    { value: 'kaarst', label: 'KAARST' },
    { value: 'koln', label: 'KOLN' },
    { value: 'krefeld', label: 'KREFELD' },
    { value: 'munchengladbach', label: 'MUNCHENGLADBACH' },
    { value: 'weeze', label: 'WEEZE' }
];

const wcOptions = [
    { value: '0', label: '0' },
    { value: '1c',nbr:'1', label: 'MainForm.wc_co' },
    { value: '2c',nbr:'2', label: 'MainForm.wc_co' },
    { value: '1nc',nbr:'1', label: 'MainForm.wc_no_co' },
    { value: '2nc',nbr:'2', label: 'MainForm.wc_no_co' },
    { value: '1c1nc',nbr:'1', label: 'MainForm.wc_co',nbr_2:'1', label_2: 'MainForm.wc_no_co' },
];

export { fraOptions, netOptions, luxOptions, gerOptions, wcOptions };
