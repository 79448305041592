export const bel_loc_values = ["crl", "bru", "midi", "central", "nord"];

export const fra_cities_values = [
  "arras",
  "cambrai",
  "charleville mezieres",
  "dunkerque",
  "hirson",
  "laon",
  "lille",
  "metz",
  "nancy",
  "paris",
  "reims",
  "rethel",
  "rocroi",
  "roubaix",
  "saint amand les eaux",
  "saint quentin",
  "thionville",
  "tourcoing",
  "trith saint leger",
  "valenciennes",
];

export const lux_cities_values = [
  "capellen",
  "diekirch",
  "differdange",
  "dippach",
  "dudelange",
  "ettelbruck",
  "luxembourg-ville",
  "mersch",
  "wiltz",
];

export const net_cities_values = [
  "amsterdam",
  "apeldoorn",
  "arnhem",
  "assen",
  "breda",
  "den haag",
  "dordrecht",
  "eindhoven",
  "etten-leur",
  "groningen",
  "heerlen",
  "helmond",
  "maastricht",
  "nijmegen",
  "oss",
  "roosendaal",
  "rotterdam",
  "s-hertogenbosch",
  "sittard",
  "terneuzen",
  "tilburg",
  "utrecht",
  "venlo",
  "vlissingen",
  "zwolle",
];

export const ger_cities_values = [
  "aachen",
  "bonn",
  "dortmund",
  "duisburg",
  "duren",
  "dusseldorf",
  "essen",
  "frankfurt",
  "kaarst",
  "koln",
  "krefeld",
  "munchengladbach",
  "weeze",
];

// zvt ------------------

export const zvt_bel_loc_km = {
  crl: 56,
  bru: 0,
  midi: 17,
  central: 14,
  nord: 10,
};

export const zvt_fra_cities_km = {
  arras: 173,
  cambrai: 147,
  "charleville mezieres": 155,
  dunkerque: 167,
  hirson: 145,
  laon: 236,
  lille: 132,
  metz: 275,
  nancy: 330,
  paris: 317,
  reims: 261,
  rethel: 207,
  rocroi: 140,
  roubaix: 127,
  "saint amand les eaux": 130,
  "saint quentin": 198,
  thionville: 250,
  tourcoing: 117,
  "trith saint leger": 127,
  valenciennes: 113,
};

export const zvt_lux_cities_km = {
  capellen: 205,
  diekirch: 195,
  differdange: 213,
  dippach: 210,
  dudelange: 232,
  ettelbruck: 216,
  "luxembourg-ville": 220,
  mersch: 227,
  wiltz: 190,
};

export const zvt_net_cities_km = {
  amsterdam: 206,
  apeldoorn: 241,
  arnhem: 208,
  assen: 341,
  breda: 100,
  "den haag": 168,
  dordrecht: 142,
  eindhoven: 145,
  "etten-leur": 113,
  groningen: 356,
  heerlen: 140,
  helmond: 161,
  maastricht: 121,
  nijmegen: 195,
  oss: 163,
  roosendaal: 114,
  rotterdam: 140,
  "s-hertogenbosch": 144,
  sittard: 130,
  terneuzen: 102,
  tilburg: 116,
  utrecht: 182,
  venlo: 167,
  vlissingen: 136,
  zwolle: 249,
};

export const zvt_ger_cities_km = {
  aachen: 112,
  bonn: 224,
  dortmund: 265,
  duisburg: 215,
  duren: 172,
  dusseldorf: 200,
  essen: 232,
  frankfurt: 394,
  kaarst: 193,
  koln: 217,
  krefeld: 196,
  munchengladbach: 177,
  weeze: 211,
};

//crl ---------------------------------
export const crl_bel_loc_km = {
  crl: 0,
  bru: 56,
  midi: 57,
  central: 60,
  nord: 63,
};

export const crl_fra_cities_km = {
  arras: 156,
  cambrai: 113,
  "charleville mezieres": 101,
  dunkerque: 192,
  hirson: 85,
  laon: 203,
  lille: 122,
  metz: 250,
  nancy: 304,
  paris: 283,
  reims: 186,
  rethel: 138,
  rocroi: 71,
  roubaix: 141,
  "saint amand les eaux": 96,
  "saint quentin": 157,
  thionville: 223,
  tourcoing: 144,
  "trith saint leger": 84,
  valenciennes: 83,
};

export const crl_lux_cities_km = {
  capellen: 177,
  diekirch: 168,
  differdange: 186,
  dippach: 182,
  dudelange: 205,
  ettelbruck: 168,
  "luxembourg-ville": 194,
  mersch: 197,
  wiltz: 143,
};

export const crl_net_cities_km = {
  amsterdam: 271,
  apeldoorn: 310,
  arnhem: 278,
  assen: 419,
  breda: 159,
  "den haag": 230,
  dordrecht: 189,
  eindhoven: 200,
  "etten-leur": 0,
  groningen: 412,
  heerlen: 137,
  helmond: 208,
  maastricht: 117,
  nijmegen: 252,
  oss: 219,
  roosendaal: 158,
  rotterdam: 209,
  "s-hertogenbosch": 0,
  sittard: 142,
  terneuzen: 144,
  tilburg: 178,
  utrecht: 231,
  venlo: 189,
  vlissingen: 185,
  zwolle: 337,
};

export const crl_ger_cities_km = {
  aachen: 140,
  bonn: 216,
  dortmund: 290,
  duisburg: 236,
  duren: 169,
  dusseldorf: 215,
  essen: 256,
  frankfurt: 393,
  kaarst: 210,
  koln: 217,
  krefeld: 252,
  munchengladbach: 231,
  weeze: 232,
};

//fin km ------------------------

export const getPriceMapping = {
  10: 81.81,
  20: 96.27,
  30: 105.47,
  40: 130.4,
  50: 248.43,
  60: 296.07,
  70: 311.95,
  80: 375.87,
  90: 387,
  100: 411,
  110: 465.15,
};

export function getPrice(value) {
  // If the value is greater than 110, return 0
  if (value > 110) {
    return 1;
  }

  // Get the keys from the dictionary and sort them in ascending order
  const keys = Object.keys(getPriceMapping)
    .map(Number)
    .sort((a, b) => a - b);

  // Find the highest key that is less than or equal to the given value
  for (let i = keys.length - 1; i >= 0; i--) {
    if (value > keys[i]) {
      return getPriceMapping[keys[i + 1]];
    } else if (value <= 10 && value > 0) {
      return getPriceMapping[keys[0]];
    } else if (value === 0) {
      return 1;
    }
  }

  // If no matching key is found (should not happen with given requirements), return 0
  return 0;
}
