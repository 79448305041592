import React from "react";
import Condition from "../components/Condition";

const Privacy = () => {
  const privacyDataItems = [
    {
      number: "1.1",
      sentence:
        "This Privacy Policy governs the manner in which we collect, use, maintain, and disclose information collected from users of our service.",
    },
    {
      number: "1.2",
      sentence:
        "By using our service, you agree to the collection and use of information in accordance with this policy.",
    },

    {
      number: "2.1",
      sentence:
        "We may collect personal identification information such as name, email address, and phone number when you use our service.",
    },
    {
      number: "2.2",
      sentence:
        "We may also collect non-personal identification information such as browser name, device type, and technical information about the means of connection to our service.",
    },

    {
      number: "3.1",
      sentence:
        "We use the collected information to improve customer service, personalize user experience, and process transactions.",
    },
    {
      number: "3.2",
      sentence:
        "We may use the email address to send updates and promotional information.",
    },

    {
      number: "4.1",
      sentence:
        "We adopt appropriate data collection, storage, and processing practices to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.",
    },

    {
      number: "5.1",
      sentence:
        "We do not sell, trade, or rent personal identification information to others.",
    },

    {
      number: "6.1",
      sentence:
        "We may update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page.",
    },
    {
      number: "6.2",
      sentence:
        "We encourage users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect.",
    },
  ];

  return (
    <div className="w-full py-16 px-4 text-white">
      <div className="max-w-[1240px] mx-auto">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          Privacy Data Policy
        </h1>
        <p className="lg:text-2xl md:text-xl text-base">
          This page outlines how we handle your personal information and
          safeguard your privacy when you use our service. It is crucial for you
          to read and understand this policy, as it contains important details
          about your rights and obligations. The policy is divided into various
          sections, each detailing a specific aspect of our privacy practices,
          from the types of information we collect to how we use and protect it.
          By using our service, you consent to the practices described in this
          policy.
        </p>
      </div>
      <div className="max-w-[1240px] mx-auto mt-5">
        <Condition
          title_number="X"
          title="Privacy Data Policy"
          items={privacyDataItems}
        />
      </div>
      <div className="max-w-[1240px] mx-auto mt-5">
        <p className="text-sm">Last update on 01/06/2024</p>
      </div>
    </div>
  );
};

export default Privacy;
