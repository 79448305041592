import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";

const FAQItem = ({ question, answer }) => {
  const primaryColor = config.env.primaryColor || "#00df9a";
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {i18n.language === "en" ? (
        <>
          <button
            className="hover:bg-indigo-400 hover:text-black bg-black border-b-8 border-gray-400 w-full px-4 sm:text-xl grid grid-cols-2"
            style={{ color: primaryColor }}
            onClick={toggleOpen}
          >
            <div className="text-left">{question}</div>
            <div className="text-right text-2xl font-bold">
              <span>{isOpen ? "-" : "+"}</span>
            </div>
          </button>
          {isOpen && <div className="bg-white mx-4 my-4">{answer}</div>}
        </>
      ) : null}
      {i18n.language === "es" ? (
        <>
          <button
            className="hover:bg-indigo-400 hover:text-black bg-black border-b-8 border-gray-400 w-full px-4 sm:text-xl grid grid-cols-2"
            style={{ color: primaryColor }}
            onClick={toggleOpen}
          >
            <div className="text-left">{question}</div>
            <div className="text-right text-2xl font-bold">
              <span>{isOpen ? "-" : "+"}</span>
            </div>
          </button>
          {isOpen && <div className="bg-white mx-4 my-4">{answer}</div>}
        </>
      ) : null}
      {i18n.language === "de" ? (
        <>
          <button
            className="hover:bg-indigo-400 hover:text-black bg-black border-b-8 border-gray-400 w-full px-4 sm:text-xl grid grid-cols-2"
            style={{ color: primaryColor }}
            onClick={toggleOpen}
          >
            <div className="text-left">{question}</div>
            <div className="text-right text-2xl font-bold">
              <span>{isOpen ? "-" : "+"}</span>
            </div>
          </button>
          {isOpen && <div className="bg-white mx-4 my-4">{answer}</div>}
        </>
      ) : null}
      {i18n.language === "po" ? (
        <>
          <button
            className="hover:bg-indigo-400 hover:text-black bg-black border-b-8 border-gray-400 w-full px-4 sm:text-xl grid grid-cols-2"
            style={{ color: primaryColor }}
            onClick={toggleOpen}
          >
            <div className="text-left">{question}</div>
            <div className="text-right text-2xl font-bold">
              <span>{isOpen ? "-" : "+"}</span>
            </div>
          </button>
          {isOpen && <div className="bg-white mx-4 my-4">{answer}</div>}
        </>
      ) : null}
      {i18n.language === "it" ? (
        <>
          <button
            className="hover:bg-indigo-400 hover:text-black bg-black border-b-8 border-gray-400 w-full px-4 sm:text-xl grid grid-cols-2"
            style={{ color: primaryColor }}
            onClick={toggleOpen}
          >
            <div className="text-left">{question}</div>
            <div className="text-right text-2xl font-bold">
              <span>{isOpen ? "-" : "+"}</span>
            </div>
          </button>
          {isOpen && <div className="bg-white mx-4 my-4">{answer}</div>}
        </>
      ) : null}
      {i18n.language === "fr" ? (
        <>
          <button
            className="hover:bg-indigo-400 hover:text-black bg-black border-b-8 border-gray-400 w-full px-4 sm:text-xl grid grid-cols-2"
            style={{ color: primaryColor }}
            onClick={toggleOpen}
          >
            <div className="text-left">{question}</div>
            <div className="text-right text-2xl font-bold">
              <span>{isOpen ? "-" : "+"}</span>
            </div>
          </button>
          {isOpen && <div className="bg-white mx-4 my-4">{answer}</div>}
        </>
      ) : null}
      {i18n.language === "ne" ? (
        <>
          <button
            className="hover:bg-indigo-400 hover:text-black bg-black border-b-8 border-gray-400 w-full px-4 sm:text-xl grid grid-cols-2"
            style={{ color: primaryColor }}
            onClick={toggleOpen}
          >
            <div className="text-left">{question}</div>
            <div className="text-right text-2xl font-bold">
              <span>{isOpen ? "-" : "+"}</span>
            </div>
          </button>
          {isOpen && <div className="bg-white mx-4 my-4">{answer}</div>}
        </>
      ) : null}
    </>
  );
};

export default FAQItem;
