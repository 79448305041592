import React from 'react'

const Condition = ({title_number, title,subtitle, items}) => {
    return (
        <div>
            <div className='mt-5'>
                <div className='flex flex-col justify-center sm:justify-between mx-4'>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2' >{title_number}.&nbsp;{title}</h2>
                    {subtitle && <p className='lg:text-xl text-base mt-2 mb-3'>{subtitle}</p>}
                    <ul className='lg:text-xl list-disc'>
                        {items.map((item, index) => (
                            <li className='mx-4 mt-1' key={index}>
                                <strong>{item.number}</strong>&nbsp;: {item.sentence}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Condition
