export const bel_loc_values = ["crl", "bru", "midi", "central", "nord"];

export const fra_cities_values = [
  "arras",
  "cambrai",
  "charleville mezieres",
  "dunkerque",
  "hirson",
  "laon",
  "lille",
  "metz",
  "nancy",
  "paris",
  "paris beauvais",
  "paris CDG",
  "reims",
  "rethel",
  "rocroi",
  "roubaix",
  "saint amand les eaux",
  "saint quentin",
  "thionville",
  "tourcoing",
  "trith saint leger",
  "valenciennes",
];

export const lux_cities_values = [
  "capellen",
  "diekirch",
  "differdange",
  "dippach",
  "dudelange",
  "ettelbruck",
  "luxembourg-ville",
  "mersch",
  "wiltz",
];

export const net_cities_values = [
  "amsterdam",
  "amsterdam schiphol",
  "apeldoorn",
  "arnhem",
  "assen",
  "breda",
  "den haag",
  "dordrecht",
  "eindhoven",
  "etten-leur",
  "groningen",
  "heerlen",
  "helmond",
  "maastricht",
  "nijmegen",
  "oss",
  "roosendaal",
  "rotterdam",
  "s-hertogenbosch",
  "sittard",
  "terneuzen",
  "tilburg",
  "utrecht",
  "venlo",
  "vlissingen",
  "zwolle",
];

export const ger_cities_values = [
  "aachen",
  "bonn",
  "dortmund",
  "duisburg",
  "duren",
  "dusseldorf",
  "essen",
  "frankfurt",
  "kaarst",
  "koln",
  "krefeld",
  "munchengladbach",
  "weeze",
];

export const zvt_bruPriceMapping = {
  crl: 91,
  midi: 42,
  central: 42,
  nord: 42,
};

export const zvt_fraPriceMapping = {
  arras: 256,
  cambrai: 223,
  "charleville mezieres": 240,
  dunkerque: 248,
  hirson: 217,
  laon: 352,
  lille: 194,
  metz: 413,
  nancy: 492,
  paris: 480,
  "paris beauvais": 400,
  "paris CDG": 400,
  reims: 338,
  rethel: 280,
  rocroi: 199,
  roubaix: 189,
  "saint amand les eaux": 195,
  "saint quentin": 284,
  thionville: 370,
  tourcoing: 176,
  "trith saint leger": 177,
  valenciennes: 176,
};

export const zvt_luxPriceMapping = {
  capellen: 305,
  diekirch: 260,
  differdange: 314,
  dippach: 311,
  dudelange: 349,
  ettelbruck: 283,
  "luxembourg-ville": 290,
  mersch: 303,
  wiltz: 253,
};

export const zvt_netPriceMapping = {
  amsterdam: 299,
  apeldoorn: 338,
  arnhem: 312,
  assen: 485,
  breda: 143,
  "den haag": 253,
  dordrecht: 182,
  eindhoven: 186,
  "etten-leur": 152,
  groningen: 519,
  heerlen: 189,
  helmond: 217,
  maastricht: 170,
  nijmegen: 284,
  oss: 234,
  roosendaal: 127,
  rotterdam: 210,
  "s-hertogenbosch": 216,
  sittard: 168,
  terneuzen: 146,
  tilburg: 171,
  utrecht: 251,
  venlo: 248,
  vlissingen: 194,
  zwolle: 375,
};

export const zvt_gerPriceMapping = {
  aachen: 210,
  bonn: 339,
  dortmund: 404,
  duisburg: 321,
  duren: 254,
  dusseldorf: 297,
  essen: 352,
  frankfurt: 596,
  kaarst: 283,
  koln: 318,
  krefeld: 286,
  munchengladbach: 241,
  weeze: 306,
};

// crl

export const crl_bruPriceMapping = {
  bru: 101,
  midi: 95,
  central: 95,
  nord: 95,
};

export const crl_fraPriceMapping = {
  arras: 243,
  cambrai: 178,
  "charleville mezieres": 153,
  dunkerque: 290,
  hirson: 135,
  laon: 245,
  lille: 184,
  metz: 376,
  nancy: 455,
  paris: 430,
  "paris beauvais": 384,
  "paris CDG": 391,
  reims: 251,
  rethel: 193,
  rocroi: 117,
  roubaix: 184,
  "saint amand les eaux": 148,
  "saint quentin": 239,
  thionville: 333,
  tourcoing: 190,
  "trith saint leger": 135,
  valenciennes: 134,
};

export const crl_luxPriceMapping = {
  capellen: 268,
  diekirch: 228,
  differdange: 278,
  dippach: 274,
  dudelange: 312,
  ettelbruck: 251,
  "luxembourg-ville": 284,
  mersch: 277,
  wiltz: 221,
};

export const crl_netPriceMapping = {
  amsterdam: 388,
  "amsterdam schiphol": 390,
  apeldoorn: 427,
  arnhem: 401,
  assen: 574,
  breda: 236,
  "den haag": 342,
  dordrecht: 271,
  eindhoven: 275,
  "etten-leur": 246,
  groningen: 609,
  heerlen: 213,
  helmond: 296,
  maastricht: 184,
  nijmegen: 378,
  oss: 336,
  roosendaal: 237,
  rotterdam: 299,
  "s-hertogenbosch": 305,
  sittard: 206,
  terneuzen: 219,
  tilburg: 260,
  utrecht: 341,
  venlo: 281,
  vlissingen: 284,
  zwolle: 464,
};

export const crl_gerPriceMapping = {
  aachen: 218,
  bonn: 339,
  dortmund: 434,
  duisburg: 354,
  duren: 254,
  dusseldorf: 329,
  essen: 385,
  frankfurt: 590,
  kaarst: 320,
  koln: 318,
  krefeld: 332,
  munchengladbach: 297,
  weeze: 373,
};
