import React from "react";
import { useTranslation } from "react-i18next";
import {
  fra_cities_values,
  ger_cities_values,
  lux_cities_values,
  net_cities_values,
} from "../utils/pricing_t2b";

const Destination = ({
  formData,
  formErrors,
  handleChange,
  handleChangeDestination,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="sm:col-span-3">
        <label
          htmlFor="passengers"
          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
        >
          {t("FormType.passengers")}
        </label>
        <div className="mt-1">
          <select
            id="passengers"
            name="passengers"
            value={formData.passengers}
            onChange={handleChange}
            required
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
          >
            {Array.from({ length: 56 }, (_, i) => i + 1).map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
          {formErrors.passengers && (
            <p className="mt-2 text-sm text-red-600" id="passengers-error">
              {formErrors.passengers}
            </p>
          )}
        </div>
      </div>
      <div className="sm:col-span-4">
        <label
          htmlFor="destination"
          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
        >
          {t("FormType.destination")}
        </label>
        <div className="mt-2">
          <select
            id="destination"
            name="destination"
            value={formData.destination}
            onChange={handleChangeDestination}
            required
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
          >
            <option value="" disabled>
              {t("FormType.type_select_2")}
            </option>
            <option value="bru">
              {t("Hero.between_1")} Brussels Zaventem Airport (BRU/EBBR)
            </option>
            <option value="crl">
              {t("Hero.between_1")} Brussels South Charleroi Airport (CRL/EBCI)
            </option>
          </select>
          {formErrors.destination && (
            <p className="mt-2 text-sm text-red-600" id="destination-error">
              {formErrors.destination}
            </p>
          )}
        </div>
      </div>

      <div className="sm:col-span-4">
        <p className="mt-1 md:text-2xl sm:text-xl text-sm leading-6 text-gray-400">
          {t("FormType.depart_title")}{" "}
        </p>
        <label
          htmlFor="country"
          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
        >
          {t("FormType.country")}
        </label>
        <div className="mt-2">
          <select
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
            autoComplete="country-name"
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
          >
            <option value="" disabled>
              {t("FormType.country_select")}
            </option>
            <option value="bel">{t("FormType.bel")}</option>
            <option value="fra">{t("FormType.fra")}</option>
            <option value="lux">{t("FormType.lux")}</option>
            <option value="net">{t("FormType.net")}</option>
            <option value="ger">{t("FormType.ger")}</option>
          </select>
          {formErrors.country && (
            <p className="mt-2 text-sm text-red-600" id="country-error">
              {formErrors.country}
            </p>
          )}
        </div>
      </div>
      {formData.country === "bel" && (
        <div className="sm:col-span-4 sm:ml-7">
          <label
            htmlFor="country_loc"
            className="block md:text-xl sm:text-base text-sm font-medium leading-6 text-white"
          >
            {t("FormType.local")}
          </label>
          <div className="mt-2">
            <select
              id="country_loc"
              name="country_loc"
              value={formData.country_loc}
              onChange={handleChange}
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
            >
              <option value="" disabled>
                {t("FormType.loc_select_2")}
              </option>
              <option value="private">{t("FormType.loc_bel_1")}</option>
              {formData.destination === "crl" && (
                <option value="bru">
                  Brussels Zaventem Airport (BRU / EBBR)
                </option>
              )}
              {formData.destination === "bru" && (
                <option value="crl">
                  Brussels South Charleroi Airport (CRL/EBCI)
                </option>
              )}
              <option value="midi">Bruxelles-Midi railway station</option>
              <option value="central">Bruxelles-Central railway station</option>
              <option value="nord">Bruxelles-Nord rail way station</option>
            </select>
          </div>
        </div>
      )}
      {formData.country === "bel" && formData.country_loc === "private" && (
        <div className="sm:col-span-4 sm:ml-14">
          <label
            htmlFor="bel_addr"
            className="block md:text-xl sm:text-base font-medium leading-6 text-white"
          >
            {t("FormType.loc_write")}
          </label>
          <div className="mt-2">
            <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                Belgium @
              </span>
              <input
                type="text"
                name="bel_addr"
                id="bel_addr"
                onChange={handleChange}
                required
                className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-base sm:leading-6"
                placeholder="1082 or Koekelberg"
              />
            </div>
          </div>
        </div>
      )}
      {formData.country === "lux" && (
        <div className="sm:col-span-4 sm:ml-7">
          <label
            htmlFor="country_loc"
            className="block md:text-xl sm:text-base text-sm font-medium leading-6 text-white"
          >
            {t("FormType.cities")}
          </label>
          <div className="mt-2">
            <select
              id="country_loc"
              name="country_loc"
              value={formData.country_loc}
              onChange={handleChange}
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
            >
              <option value="" disabled>
                {t("FormType.loc_select_2")}
              </option>
              {lux_cities_values.map((city) => (
                <option key={city} value={city}>
                  {city.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {formData.country === "fra" && (
        <div className="sm:col-span-4 sm:ml-7">
          <label
            htmlFor="country_loc"
            className="block md:text-xl sm:text-base text-sm font-medium leading-6 text-white"
          >
            {t("FormType.cities")}
          </label>
          <div className="mt-2">
            <select
              id="country_loc"
              name="country_loc"
              value={formData.country_loc}
              onChange={handleChange}
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
            >
              <option value="" disabled>
                {t("FormType.loc_select_2")}
              </option>
              {fra_cities_values.map((city) => (
                <option key={city} value={city}>
                  {city.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {formData.country === "net" && (
        <div className="sm:col-span-4 sm:ml-7">
          <label
            htmlFor="country_loc"
            className="block md:text-xl sm:text-base text-sm font-medium leading-6 text-white"
          >
            {t("FormType.cities")}
          </label>
          <div className="mt-2">
            <select
              id="country_loc"
              name="country_loc"
              value={formData.country_loc}
              onChange={handleChange}
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
            >
              <option value="" disabled>
                {t("FormType.loc_select_2")}
              </option>
              {net_cities_values.map((city) => (
                <option key={city} value={city}>
                  {city.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {formData.country === "ger" && (
        <div className="sm:col-span-4 sm:ml-7">
          <label
            htmlFor="country_loc"
            className="block md:text-xl sm:text-base text-sm font-medium leading-6 text-white"
          >
            {t("FormType.cities")}
          </label>
          <div className="mt-2">
            <select
              id="country_loc"
              name="country_loc"
              value={formData.country_loc}
              onChange={handleChange}
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
            >
              <option value="" disabled>
                {t("FormType.loc_select_2")}
              </option>
              {ger_cities_values.map((city) => (
                <option key={city} value={city}>
                  {city.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default Destination;
