import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

const Newsletter = () => {
  const { t } = useTranslation();
  const [passengers, setPassengers] = useState(1); // Default to 1
  const [airport, setAirport] = useState(""); // Default to empty string
  const [mode, setMode] = useState(""); // Default to empty string
  const primaryColor = config.env.primaryColor || "#00df9a";
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "passenger") {
      setPassengers(value);
    } else if (name === "airport") {
      setAirport(value);
    } else if (name === "mode") {
      setMode(value);
    }
  };

  return (
    <div className="w-full py-16 text-white px-4">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-4">
        <div className="lg:col-span-3 my-2">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("CTA.main")}
          </h1>
          <p>{t("CTA.subtitle")}</p>
        </div>
        <div className="my-4">
          <div className="flex flex-col items-center justify-between w-full">
            <input
              name="passenger"
              onChange={handleInputChange}
              className=" p-3 w-full rounded-md text-black"
              type="texte"
              placeholder={t("CTA.passengers")}
            />
            <select
              name="airport"
              onChange={handleInputChange}
              defaultValue={"DEFAULT"}
              className="mt-3 p-3 w-full rounded-md text-black"
            >
              <option value="DEFAULT" disabled>
                {t("CTA.airport")}
              </option>
              <option value="bru">Brussels Zaventem</option>
              <option value="crl">Charleroi Brussels South</option>
            </select>
            <select
              name="mode"
              onChange={handleInputChange}
              defaultValue={"DEFAULT"}
              className="mt-3 p-3 w-full rounded-md text-black"
            >
              <option value="DEFAULT" disabled>
                {t("CTA.mode")}
              </option>
              <option value="destination">{t("CTA.mode_1")}</option>
              <option value="departure">{t("CTA.mode_2")}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="max-w-[1240px] mx-auto">
        <Link
          to={`/booking/${mode}?passengers=${passengers}&airport=${airport}`}
        >
          <button
            disabled={mode !== "destination" && mode !== "departure"}
            className="w-full rounded-md font-medium my-6 mx-auto py-3 text-black enabled:hover:bg-indigo-400 disabled:text-slate-500"
            style={{ backgroundColor: primaryColor }}
          >
            {t("Button.cta")}
          </button>
        </Link>
        <p>
          {t("CTA.note")}{" "}
          <Link to="/privacy">
            <span className="" style={{ color: primaryColor }}>
              {t("CTA.link")}.
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Newsletter;
