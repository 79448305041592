import React from 'react'
import { useTranslation } from 'react-i18next';

const FlightInfo = ({formData,formErrors, handleChange}) => {
    const {t}=useTranslation();
    
    return (
    <>
        <div className="sm:col-span-4 border-b border-t pt-4 border-white/100 pb-4">
            <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">Flight information</h2>
            <p className="mt-1 md:text-2xl sm:text-xl text-sm leading-6 text-gray-400">Be aware that we could cancel your trip if you fail to provide correct travel details (flight, route, dates)</p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label htmlFor="fligh_desti" className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white">Flight destination</label>
                    <div className="mt-2">
                        <input disabled type="text" name="fligh_desti" id="fligh_desti" value={formData.departure === 'bru' ? 'Brussels Zaventem Airport (BRU/EBBR)':'South Charleroi Airport (CRL/EBCI)'} onChange={handleChange} className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 disabled:text-slate-500" />
                        {formErrors.fligh_desti && <p className="mt-2 text-sm text-red-600" id="fligh_desti-error">{formErrors.fligh_desti}</p>}
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="fligh_origin" className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white">{t('Flight.origin')}</label>
                    <div className="mt-2">
                        <input type="text" name="fligh_origin" id="fligh_origin" value={formData.fligh_origin || ''} onChange={handleChange} required className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6" />
                        {formErrors.fligh_origin && <p className="mt-2 text-sm text-red-600" id="fligh_origin-error">{formErrors.fligh_origin}</p>}
                    </div>
                </div>

                <div className="sm:col-span-4">
                    <label htmlFor="fligh_number" className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white">{t('Flight.number')}</label>
                    <div className="mt-2">
                        <input id="fligh_number" name="fligh_number" type="text" value={formData.fligh_number || ''} onChange={handleChange} required className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6" />
                        {formErrors.fligh_number && <p className="mt-2 text-sm text-red-600" id="fligh_number-error">{formErrors.fligh_number}</p>}
                    </div>
                </div>

                <div className="sm:col-span-4">
                    <label htmlFor="arrival_time" className="mb-2 block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white">{t('Flight.a_time')}</label>
                    <div className="mt-2">
                        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                            <input type="time" name="arrival_time" id="arrival_time" onChange={handleChange} value={formData.arrival_time || ''} required className="flex-1 border-0 bg-transparent pl-1 text-white focus:ring-0 sm:text-base sm:leading-6" />
                            {formErrors.arrival_time && <p className="mt-2 text-sm text-red-600" id="arrival_time-error">{formErrors.arrival_time}</p>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default FlightInfo